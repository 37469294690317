import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  ClickAwayListener,
  IconButton,
  Menu,
  Stack,
  Toolbar,
  styled,
} from "@mui/material";
import { useState } from "react";
import { pages } from "../routing";
import { LinkButton } from "./LinkButton";
import { MenuLink } from "./MenuLink";

type NavItem = {
  label: string;
  path: string;
};

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <HeaderBar
        sx={{
          height: {
            xs: "6rem",
            sm: "10rem",
          },
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          direction={{
            xs: "row",
            sm: "column",
          }}
        >
          <Box
            sx={{
              py: 2,
            }}
          >
            <img
              src="/images/logo-small.png"
              alt="Malleable logo"
              height="60"
            />
          </Box>
          <Box>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <>
                <IconButton
                  sx={{
                    marginLeft: "1.5rem",
                    display: {
                      xs: "flex",
                      sm: "none",
                    },
                  }}
                  color="inherit"
                  size="large"
                  onClick={handleClick}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
                <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                  {pages.map((item) => (
                    <MenuLink
                      key={item.path}
                      to={item.path}
                      sx={{ m: 0 }}
                      onClick={() => setAnchorEl(null)}
                    >
                      {item?.title}
                    </MenuLink>
                  ))}
                </Menu>
              </>
            </ClickAwayListener>
          </Box>
          <Toolbar
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
          >
            <LinkButton to="/">Home</LinkButton>
            {pages.map((item) => (
              <NavItemContainer key={item.path} to={item.path}>
                {item?.title}
              </NavItemContainer>
            ))}
          </Toolbar>
        </Stack>
      </HeaderBar>
      <HeaderSpacing
        sx={{
          height: {
            xs: "6rem",
            sm: "10rem",
          },
        }}
      />
    </>
  );
};

const NavItemContainer = styled(LinkButton)(({ theme }) => ({
  color: "white",
  fontWeight: 500,
  fontSize: theme.typography.fontSize * 1.125,
  padding: theme.spacing(2, 6),
  transition: "background 0.2s ease-in-out",
  cursor: "pointer",
  "&:hover": {
    background: theme.palette.primary.light,
  },
}));

const HeaderBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main,
}));
const HeaderSpacing = styled(Box)(({ theme }) => ({
  height: theme.spacing(20),
}));
