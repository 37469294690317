import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
export const MenuLink = (props: any) => (
  <MenuItem
    component={Link}
    to={props.to}
    color={props.color || "primary"}
    {...props}
  />
);
