import { Alert, Typography } from "@mui/material";
import Title from "../components/Title";

export default function Bookings() {
  return (
    <>
      <Title>Bookings</Title>
      <Typography variant="body1">
        We currently provide ad hoc IFS Document Management and IFS Financials
        sessions remotely by the hour, these sessions can be tailored to your
        requirements if booked in advance or picked from the some previously
        requested sessions below. Please check availability and book in with one
        of experts in the system at the bottom of this page.
      </Typography>
      <Typography variant="h3">
        An Introduction to Document Management
      </Typography>
      <Typography variant="body1">
        An explanation of the core concepts and methodologies in IFS Document
        Management. This session provides a suggested Basic Data set up, process
        flow, examples and implementation pathway.
      </Typography>
      <Typography variant="body1">
        Suitable for customers with no IFS Document Management experience.
      </Typography>
      <Alert severity="info" sx={{ my: 3 }}>
        <strong>Please note:</strong> Unless granted access prior to the
        session, consultants will not have access to your IFS Database. All
        materials and slide decks will have been generated in advance and can be
        shared at the consultants discretion. If you require a company specific
        solution, most customers will share their screen and be guided through
        the navigation by our expert.
      </Alert>
      <Typography variant="h3">Price List</Typography>
      <Typography variant="body1">
        <strong>£100.00 (plus VAT)</strong> per 1-hour session.
      </Typography>
      <script
        id="setmore_script"
        type="text/javascript"
        src="https://storage.googleapis.com/fullintegration-live/webComponentAppListing/Container/setmoreIframeLive.js"
      ></script>
      <a
        id="Setmore_button_iframe"
        href="https://booking.setmore.com/scheduleappointment/fae32292-8a57-489b-95f0-75c336cfa851"
      >
        <img
          src="https://storage.googleapis.com/full-assets/setmore/images/1.0/Settings/book-now-blue.svg"
          alt="Book an appointment with Matt using Setmore"
        />
      </a>
    </>
  );
}
