import { ThemeProvider, createTheme } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { routing } from "./routing";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2A293E",
      },
      secondary: {
        main: "#FF0000",
      },
      background: {
        default: "#2A293E",
        paper: "#E3E5DF",
      },
    },
    typography: {
      fontFamily: "Rubik",
      h1: {
        fontSize: "2.5rem",
        marginBottom: "0.5rem",
        fontWeight: 700,
      },
      h2: {
        fontSize: "2rem",
        marginBottom: "0.5rem",
        fontWeight: 700,
      },
      h3: {
        fontSize: "1.5rem",
        fontWeight: 500,
        marginBottom: "0.5rem",
      },
      body1: {
        fontSize: "1rem",
        fontWeight: 400,
        marginBottom: "1rem",
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            body1: "p",
          },
        },
      },
    },
  });

  const router = createBrowserRouter(routing);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
