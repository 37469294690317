import { RouteObject } from "react-router-dom";
import Bookings from "./pages/Bookings";
import Home from "./pages/Home";
import References from "./pages/References";
import Skills from "./pages/Skills";
import Root from "./pages/Root";

export type RoutingWithTitle = RouteObject & {
  title: string;
};

export const pages: RoutingWithTitle[] = [
  {
    path: "/",
    title: "Home",
    element: <Home />,
  },
  {
    path: "/skills",
    title: "Skills",
    element: <Skills />,
  },
  {
    path: "/references",
    title: "References",
    element: <References />,
  },
  {
    path: "/bookings",
    title: "Bookings & Availability",
    element: <Bookings />,
  },
];

export const routing: RouteObject[] = [
  {
    path: "/",
    element: <Root />,
    children: pages.map((page) => ({
      path: page.path,
      element: page.element,
    })),
  },
];
