import { Grid, Stack, Typography } from "@mui/material";
import Title from "../components/Title";

export default function Home() {
  return (
    <Stack spacing={6}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Title>Who We Are</Title>
          <Typography variant="body1">
            Malleable Consulting was formed in 2023 to bring reliable, logical
            and diligent solutions to businesses implementing IFS Applications.
          </Typography>
          <Typography variant="body1">
            With a Strong Track Record of successful implementations, support,
            and training courses, Malleable Consulting is always looking to
            expand its customer base and help businesses get the most out of
            their IFS Solution.
          </Typography>
          <Typography variant="body1">
            We look forward to hearing from you.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} alignItems="center" display="flex">
          <img
            src="/images/boardroom.jpg"
            alt="Meeting room"
            style={{ maxWidth: "100%", maxHeight: "20rem", margin: "0 auto" }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <img
            src="/images/matt.jpg"
            alt="A portrait of Matt"
            style={{ maxWidth: "100%", maxHeight: "24rem", margin: "0 auto" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Title>Matt Spence</Title>
          <Typography variant="h3">
            Director &amp; Principal Consultant
          </Typography>
          <Typography variant="body1">
            Matt is the Director and Principal Consultant at Malleable
            Consulting.
          </Typography>
          <Typography variant="body1">
            Having first been introduced into the IFS world in 2017 as part of
            the IFS Graduate Scheme, Matt quickly developed a strong reputation
            with both Consultants and Customers. After progressing through the
            ranks of consulting at IFS, Matt left in February 2023 to start
            Malleable Consulting.
          </Typography>
          <Typography variant="body1">
            He has a proven track record of success implementing IFS Solutions
            on Apps 9, Apps 10, Apps 10 Aurena and IFS Cloud.
          </Typography>
          <Typography variant="body1">
            His primary skillset is based on IFS Financials, IFS Document
            Management and Solution Architecture. Check out his competencies for
            more information.
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
}
