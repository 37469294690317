import { Box, Container, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import { Header } from "../components/Header";

export default function Root() {
  return (
    <Body>
      <Header />
      <PageContainer>
        <Container>
          <Outlet />
        </Container>
      </PageContainer>
      <Footer />
    </Body>
  );
}

const PageContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(4, 2),
}));

const Body = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  minHeight: "100vh",
}));
