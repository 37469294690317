import { Box, List, ListItem, Stack, styled } from "@mui/material";
import Title from "../components/Title";

export default function Skills() {
  return (
    <>
      <Title>Skills</Title>
      <Stack spacing={6}>
        <Box>
          <Title>IFS Financials</Title>
          <List>
            <SkillItem>General Ledger</SkillItem>
            <SkillItem>Accounts Payable</SkillItem>
            <SkillItem>Accounts Receivable</SkillItem>
            <SkillItem>Cashbook &amp; Treasury</SkillItem>
            <SkillItem>Group Consolidation</SkillItem>
            <SkillItem>Reporting</SkillItem>
          </List>
        </Box>
        <Box>
          <Title>IFS Document Management</Title>
          <List>
            <SkillItem>Document Class Structure</SkillItem>
            <SkillItem>Workflow Processes</SkillItem>
            <SkillItem>Macros & Automation</SkillItem>
            <SkillItem>Document Transmittals</SkillItem>
            <SkillItem>Document Packages</SkillItem>
          </List>
        </Box>
      </Stack>
    </>
  );
}

const SkillItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  fontSize: theme.typography.fontSize * 1.25,
  padding: theme.spacing(1, 3),
}));
