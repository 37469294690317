import { Grid, Stack } from "@mui/material";
import Title from "../components/Title";

export default function References() {
  const references = [
    {
      name: "Aston Martin Formula One Team",
      img: "astonmartin.jpeg",
    },
    {
      name: "Briggs Equipment",
      img: "briggs.png",
    },
    {
      name: "Global Marine Group",
      img: "globalmarine.png",
    },
    {
      name: "William Grant & Sons",
      img: "williamgrant.png",
    },
    {
      name: "Frei Lacke",
      img: "freilacke.png",
    },
    {
      name: "Legal & General",
      img: "legalandgen.png",
    },
  ];

  return (
    <Stack>
      <Title>References</Title>
      <Grid container>
        {references.map((ref) => {
          return (
            <Grid
              item
              xs={12}
              md={6}
              alignItems="center"
              display="flex"
              sx={{
                minHeight: "12rem",
              }}
            >
              <img
                src={`/images/${ref.img}`}
                alt={ref.name}
                style={{
                  maxWidth: "100%",
                  maxHeight: "20rem",
                  margin: "0 auto",
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
}
