import { Box, Grid, Stack, styled } from "@mui/material";

export default function Footer() {
  const m = `admin${String.fromCharCode(
    64
  )}malleableconsulting${String.fromCharCode(46)}com`;
  return (
    <FooterContainer>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={2}
        justifyContent="space-between"
      >
        <Box>
          <Grid
            container
            sx={{
              maxWidth: "30rem",
            }}
            spacing={2}
          >
            <Grid item md={4}>
              Registered Address
            </Grid>
            <Grid item md={8}>
              86-90, Paul Street, London, England, United Kingdom, EC2A 4NE
            </Grid>
            <Grid item md={4}>
              Phone
            </Grid>
            <Grid item md={8}>
              +44 (0) 7555 112 854
            </Grid>
            <Grid item md={4}>
              Email
            </Grid>
            <Grid item md={8}>
              <a href={"mailto:" + m} style={{ color: "inherit" }}>
                admin&#64;malleableconsulting&#46;com
              </a>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <p>
            <img src="/images/logo-small.png" alt="Malleable logo" />
          </p>
          <p>Company No. 14605309</p>
          <p>© 2023 Malleable Consulting Ltd. All rights reserved.</p>
        </Box>
      </Stack>
    </FooterContainer>
  );
}

const FooterContainer = styled(Box)(({ theme }) => ({
  color: "white",
  background: theme.palette.primary.main,
  fontWeight: 500,
  fontSize: theme.typography.fontSize * 1.125,
  padding: theme.spacing(4, 8),
}));
