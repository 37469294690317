import { Button } from "@mui/material";
import { Link } from "react-router-dom";
export const LinkButton = (props: any) => (
  <Button
    component={Link}
    to={props.to}
    color={props.color || "primary"}
    {...props}
  />
);
