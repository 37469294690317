import { Typography, styled } from "@mui/material";

export default function Title(props: { children: React.ReactNode }) {
  return (
    <Typography variant="h1">
      {props.children}
      <FullStop>.</FullStop>
    </Typography>
  );
}

const FullStop = styled("span")(({ theme }) => ({
  color: theme.palette.secondary.main,
}));
